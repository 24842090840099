import React from "react";
import Section from "../components/section";
import Container from "../components/container";
import Title from "../components/title";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import style from "./about.module.css";
export default function About() {
  const data = useStaticQuery(graphql`
    query {
      curl: file(relativePath: { eq: "photos/curl.JPG" }) {
        childImageSharp {
          fluid(maxHeight: 500, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      competition: file(relativePath: { eq: "competition.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 500, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kettlebell: file(relativePath: { eq: "photos/kettlebell.JPG" }) {
        childImageSharp {
          fluid(maxHeight: 500, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Section className={`bg-black text-white`}>
      {/* <BackgroundImage fluid={data.image.childImageSharp.fluid} Tag="div"> */}
      <Container padding>
        <Title
          bottom={`LOFORSEN`}
          color={`white`}
          name={`about`}
          top={`ROBERT`}
        />
        {/* <div className={`flex flex-col-reverse md:flex-row justify-between`}>
          <div className={`w-full md:w-1/3 md:my-8 md:mr-8`}>
            <div className={`mb-8`}>
              <span>
                <Img fluid={data.curl.childImageSharp.fluid}></Img>
              </span>
            </div>
            <div className={`mb-8`}>
              <span>
                <Img fluid={data.kettlebell.childImageSharp.fluid}></Img>
              </span>
            </div>
            <span>
              <Img fluid={data.competition.childImageSharp.fluid}></Img>
            </span>
          </div>
          <div className={`flex-grow w-full md:w-1/2 py-4`}>
            <p className={`pt-4 text-xl`}>
              Mitt namn är Robert Loforsen. Hos mig får du individuella tränings
              – och kostupplägg online så att du enkelt kan följa ditt
              skräddarsydda program i syfte att nå dina personliga mål. Med min
              breda kunskap och långa erfarenhet inom personlig träning vill jag
              hjälpa dig att få ut de resultat som du önskar av din träning. Jag
              startade för 20 år sedan som tävlande atlet men arbetar idag med
              det som jag brinner för, att se mina klienter nå sin framgång och
              uppnå sina mål. För mig är förståelsen för din resa grunden för
              att nå ett lyckat resultat. <br />
              <br />
              Oavsett om du vill bygga muskler eller förbättra din generella
              form kan jag hjälpa dig med ett upplägg som passar dig, dina unika
              behov och dina individuella mål. Min personliga inspiration kommer
              från att ständigt hitta nya vägar framåt och min drivkraft är
              kunskap. Mina upplägg baseras därför på många år av lång
              erfarenhet i kombination med min strävan att tillsammans med dig
              hitta rätt väg för att nå ditt slutmål. Med bakgrund som coach för
              toppresterande atleter inom Fitness Branchen. Tror på att se varje
              klients individuella förutsättningar, ständigt sträva mot att
              hitta lösningar samt att få möjlighet att förmedla min kunskap
              vidare. Detta är för mig nycklarna till den framgång som lett till
              bland annat VM, EM och SM meriter för mina atleter. Idag har jag
              förmånen att arbeta tillsammans med en bredare målgrupp och större
              variation av klienter. Min kompetens inom personlig träning har
              gjort mig till en ofta anlitad coach.
            </p>
          </div>
        </div> */}
      </Container>
      <Container padding>
        <div className={style.about}>
          <span>
            <Img fluid={data.curl.childImageSharp.fluid}></Img>
          </span>
          <p className={`text-xl`}>
            Mitt namn är Robert Loforsen. Hos mig får du individuella tränings –
            och kostupplägg online så att du enkelt kan följa ditt skräddarsydda
            program i syfte att nå dina personliga mål. Med min breda kunskap
            och långa erfarenhet inom personlig träning vill jag hjälpa dig att
            få ut de resultat som du önskar av din träning. Jag startade för 20
            år sedan som tävlande atlet men arbetar idag med det som jag brinner
            för, att se mina klienter nå sin framgång och uppnå sina mål.
          </p>
          <span>
            <Img fluid={data.kettlebell.childImageSharp.fluid}></Img>
          </span>
          <p className={`text-xl`}>
            För mig är förståelsen för din resa grunden för att nå ett lyckat
            resultat. Oavsett om du vill bygga muskler eller förbättra din
            generella form kan jag hjälpa dig med ett upplägg som passar dig,
            dina unika behov och dina individuella mål. Min personliga
            inspiration kommer från att ständigt hitta nya vägar framåt och min
            drivkraft är kunskap. Mina upplägg baseras därför på många år av
            lång erfarenhet i kombination med min strävan att tillsammans med
            dig hitta rätt väg för att nå ditt slutmål. Med bakgrund som coach
            för toppresterande atleter inom Fitness Branchen.
          </p>
          <span>
            <Img fluid={data.competition.childImageSharp.fluid}></Img>
          </span>
          <p className={`text-xl`}>
            Tror på att se varje klients individuella förutsättningar, ständigt
            sträva mot att hitta lösningar samt att få möjlighet att förmedla
            min kunskap vidare. Detta är för mig nycklarna till den framgång som
            lett till bland annat VM, EM och SM meriter för mina atleter. Idag
            har jag förmånen att arbeta tillsammans med en bredare målgrupp och
            större variation av klienter. Min kompetens inom personlig träning
            har gjort mig till en ofta anlitad coach.
          </p>
        </div>
      </Container>
    </Section>
  );
}
