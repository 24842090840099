import React from "react";
import Section from "../components/section";
import Container from "../components/container";
import Title from "../components/title";
import LenusForm from "../components/lenusform";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export default function Signup() {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "photos/gasp.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Section className={``}>
      <Container>
        <div className={`flex flex-col md:flex-row justify-between`}>
          <div className={`flex-grow p-4 pb-8`}>
            <Title bottom={`MIN VISION`} name={`signup`} top={`DINA MÅL`} />
            {/* <LenusForm></LenusForm> */}
          </div>
          <div className={`w-full md:w-1/2 md:my-8 `}>
            <span>
              <Img fluid={data.image.childImageSharp.fluid}></Img>
            </span>
          </div>
        </div>
      </Container>
    </Section>
  );
}
