import React from "react";
import cn from "classnames";
export default function Button({
  as = `button`,
  children,
  outlined,
  full,
  white,
  ...props
}) {
  const Component = as;
  const classNames = cn([
    `p-4 font-bold text-center`,
    outlined ? `border-4 text-black` : `bg-black text-white`,
    white ? `border-white text-white` : `border-black`,
    full ? `block` : `inline-block`
  ]);

  return (
    <Component className={classNames} {...props}>
      {children}
    </Component>
  );
}
