import React from "react";
import Section from "../components/section";
import Container from "../components/container";
import Title from "../components/title";
import LenusForm from "../components/lenusform";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export default function Quote() {
  return (
    <Section className={`bg-black text-gray-600 py-8`}>
      <Container padding>
        <p className={`text-4xl  font-bold `}>
          ”Kunskap kan vi enkelt skaffa oss, <br />
          men kompetens är ett resultat av många år av framgång och hårt
          arbete.”
        </p>
      </Container>
    </Section>
  );
}
