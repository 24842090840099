import React from "react";
import Section from "../components/section";
import Container from "../components/container";
import Title from "../components/title";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "../components/carousel";
import Button from "../components/button";
export default function Testamonials() {
  const { image } = useStaticQuery(graphql`
    query {
      image: allFile(
        filter: { relativeDirectory: { eq: "transformations" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Section className={`bg-black`}>
      <Container padding>
        <Title bottom={`& EFTER`} top={`FÖRE`} color={`white`} />
        <Carousel>
          {image.edges.map((node, id) => {
            return <Img fluid={node.node.childImageSharp.fluid}></Img>;
          })}
        </Carousel>
        <div className={`mt-8`}>
          <Button as={`a`} href={`#signup`} outlined white full>
            Kontakta mig
          </Button>
        </div>
      </Container>
    </Section>
  );
}
