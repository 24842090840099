import React from "react";
import Section from "../components/section";
import Container from "../components/container";
import Title from "../components/title";
import Button from "../components/button";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Coaching() {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "devices.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iphone: file(relativePath: { eq: "iphone.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Section className="bg-gray-200">
      <Container padding>
        <Title bottom={`COACHING`} top={`ONLINE`} />
        <div className={` flex flex-col md:flex-row`}>
          <div className={`w-full md:w-1/2`}>
            <p className={`text-xl py-4`}>
              Med min vägledning får du effektivt ta del av 25 års kompetens som
              optimerar dina resultat.
              <br />
              <br /> Detta ingår med mig som online coach:
            </p>
            <ul className={`list-disc`}>
              <li className={`text-xl m-4 font-bold`}>Personligt kostschema</li>
              <li className={`text-xl m-4 font-bold`}>
                Skräddarsydd träningsplan
              </li>
              <li className={`text-xl m-4 font-bold`}>
                Grundlig analys av din kropp och fysiska profil
              </li>
              <li className={`text-xl m-4 font-bold`}>
                Kontinuerlig uppföljning och stöd
              </li>
              <li className={`text-xl m-4 font-bold`}>
                Live chatt för frågor och motivation
              </li>
            </ul>
          </div>
          <div className={`w-full md:w-1/2 `}>
            <Img fluid={data.iphone.childImageSharp.fluid}></Img>
          </div>
        </div>
        <div className={`mt-8`}>
          <Button as={`a`} full href="#signup">
            Signa upp
          </Button>
        </div>
      </Container>
      <Container>
        <Img fluid={data.image.childImageSharp.fluid}></Img>
      </Container>
    </Section>
  );
}
