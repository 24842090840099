import React, { Children, useRef, useEffect, useState } from "react";

export default function Carousel({ children }) {
  const carouselRef = useRef();
  const itemRefs = useRef({});
  const [activeIndex, setActiveIndex] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const activeEntries = entries.reduce((acc, entry) => {
          if (entry.intersectionRatio > 0.8) {
            acc.push(parseInt(entry.target.dataset.carouselId));
          }
          return acc;
        }, []);
        setActiveIndex(activeEntries);
      },
      {
        root: carouselRef.current,
        threshold: 1
      }
    );
    Object.entries(itemRefs.current).forEach(([index, target]) => {
      observer.observe(target);
    });
  }, [children.length]);

  return (
    <>
      <div
        ref={carouselRef}
        style={{
          display: `inline-flex`,
          overflowX: `scroll`,
          scrollSnapType: `x mandatory`,
          WebkitOverflowScrolling: `touch`,
          width: `100%`,
          position: `relative`
        }}
      >
        {Children.map(children, (child, index) => {
          return (
            <div
              data-carousel-id={index}
              style={{
                scrollSnapAlign: `center`,
                flexShrink: 0,
                width: `100%`,
                margin: 10
              }}
              ref={item => {
                itemRefs.current[index] = item;
              }}
            >
              {child}
            </div>
          );
        })}
      </div>
      {Children.map(children, (item, index) => {
        const isActive = activeIndex.includes(index);
        return (
          <button
            style={{
              borderRadius: 100,
              width: 10,
              height: 10,
              backgroundColor: isActive ? `#FFF` : `#4f4f4f`,
              margin: 10
            }}
          ></button>
        );
      })}
    </>
  );
}
