import React from "react";
import Section from "../components/section";
import Container from "../components/container";
import Title from "../components/title";
import LenusForm from "../components/lenusform";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export default function Philosophy() {
  return (
    <Section className={`bg-gray-100 text-gray-900 py-8`}>
      <Container padding>
        <div className={`flex flex-col md:flex-row`}>
          <div className={`w-full md:w-1/3  md:m-4`}>
            <div className={`p-4 bg-black text-white text-xl font-black mb-4`}>
              Träningsfilosofi
            </div>
            <p className={`mb-8 p-4`}>
              Min filosofi kring träningen är att jag tror på att göra vikten
              tung och prickskjuta muskeln. Att man kan kombinera med att öka
              intensiteten och vikten för för att få maximal utveckling
            </p>
          </div>
          <div className={`w-full md:w-1/3  md:m-4`}>
            <div className={`p-4 bg-black text-white text-xl font-black mb-4`}>
              Kosthållning
            </div>
            <p className={`mb-8 p-4`}>
              Min filosofi kring kosten är att Variera maten och protein
              källorna, men att hålla den så "Clean" som möjligt. Lägga sig på
              ett intag man klarar under en längre tid för att inte falla. För
              bestående resultat så behövs en bestående sund inställning till
              maten.
            </p>
          </div>
          <div className={`w-full md:w-1/3  md:m-4`}>
            <div className={`p-4 bg-black text-white text-xl font-black mb-4`}>
              Mindset
            </div>
            <p className={`mb-8 p-4`}>
              Sinnet styr kroppen och ett starkt sinne kommer resultera i en
              stark kropp och öppna upp möjligheter att nå dit man trodde var
              omöjligt. Tänk posetivt och tro på dig själv! I slutändan är det
              som styr resultatet. Där det finns en vilja finns också en väg.
            </p>
          </div>
        </div>
      </Container>
    </Section>
  );
}
