import React from "react";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import Container from "../components/container";
import Title from "../components/title";
import Button from "../components/button";
import { useStaticQuery, graphql } from "gatsby";
import LenusForm from "../components/lenusform";

export default function Hero({ children, imageSrc }) {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "photos/hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={`bg-black`}>
      <Container>
        <BackgroundImage
          className={`h-screen relative bg-top`}
          fluid={data.image.childImageSharp.fluid}
          style={{ backgroundPosition: `top 500px` }}
          Tag="section"
        >
          <Container>
            <div className={`px-4 md:px-8`}>
              <Title
                bottom={`RÄKNAS`}
                color={`white`}
                top={`NÄR RESULTATET`}
              ></Title>

              {children}
            </div>
          </Container>
        </BackgroundImage>
      </Container>
    </div>
  );
}
