import "intersection-observer";
import React from "react";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../sections/hero";
import Signup from "../sections/signup";
import Transformations from "../sections/transformations";
import About from "../sections/about";
import Coaching from "../sections/coaching";
import Quote from "../sections/quote";
import Philosophy from "../sections/philosophy";
function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `personlig`,
          `träning`,
          `personlig träning`,
          `coaching`,
          `bodybuilding`,
          `motivation`,
          `PT`,
          `personal trainer`,
          `online coach`,
          `online`,
          `athlete`,
        ]}
        title="Robert Loforsen - Personlig coaching online"
      />
      <Hero></Hero>
      <Signup></Signup>
      <Coaching></Coaching>
      <Quote></Quote>
      <Transformations></Transformations>
      <Philosophy></Philosophy>
      <About></About>
    </Layout>
  );
}

export default IndexPage;
